<template>

    <div class="signup" v-if="signupVisible">
        <div class="signup_page">
            <div class="signup_column_1">
                <h1>Hello, Friend!</h1>
                <p class="signup_text_1">Welcome to be one of us.<br>Enter your personal info and start your connection with us!</p>
                <div class="signin_button" @click="$emit('signin')">
                    <router-link class="signin_button_1" to="">SIGN IN</router-link>
                </div>
            </div>
            <div class="signup_column_2">
                <h2>Create Account</h2>
                <div class="signup_icon">
                    <a href="https://www.facebook.com" target="_blank" class="social_icon">
                        <i class="fab fa-facebook-f"></i>
                    </a>
                    <a href="https://www.twitter.com" target="_blank" class="social_icon">
                        <i class="fab fa-twitter"></i>
                    </a>
                    <a href="https://www.instagram.com" target="_blank" class="social_icon">
                        <i class="fab fa-instagram"></i>
                    </a>
                </div>
                <p class="signup_text_2">Or use your email for registration</p>
                <form @submit.prevent="register">
                    <div class="signup_form">
                        <input type="text" v-model="username" placeholder="  Name " class="signup_input">
                        <input type="email" v-model="email" placeholder="  Email " class="signup_input">
                        <input type="password" v-model="password" placeholder="  Password " class="signup_input">
                    </div>
                    <div class="signup_button" >
                        <button type="submit" class="signin_button_2">SIGN UP</button>
                    </div>
                </form>
                
            </div>
            <div class="signup_close" @click="closeSignPage">
                <span class="signup_close_icon"></span>
            </div>     
        </div>
    </div>
</template>

<script>
export default{
    props:{
        signupVisible:{
            type:Boolean,
            default:false
        }
    },
    
    data(){
        return {
            username: '',
            email:'',
            password: ''
        };
    },
    
    methods:{
        closeSignPage(){
            this.$emit('update:signupVisible',false);
        },

        async register(){
            try {
                const response = await fetch('https://songwen-2024-new.ew.r.appspot.com/register',{
                    method: 'POST' ,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        username: this.username,
                        email:this.email,
                        password: this.password
                    })
                });
                const data = await response.json();
                console.log(data);
                if (response.ok){
                    alert('registered successfully!');
                } else {
                    alert(`failed to register: ${data.message}`);
                }
            } catch (error) {
                    console.error('Error:', error);
                    alert('failed to register, try again.')
            }   
        }
    }
};
</script>


<style scoped>

.signup{
    z-index: 9999;
    position: fixed;
    display: flex;
    margin: 0;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.5)
}

.signup_page{
    width: 60vw;
    height: 75vh;
    position: relative;
    display: grid;
    grid-template-columns: 45% 55%;
    background-color: #5E5E5E;
}

.signup_column_1{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    opacity: 1;
    z-index: 1;
}

.signup_column_1::before{
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background-image: url('../assets/header_6.jpg');
    background-size: cover;
    opacity: 0.4;
    z-index: -1;
}

.signup_page .signup_column_2{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #5E5E5E;
}

h1, h2{
    text-align: center;
}

.signup_text_1, .signup_text_2{
    margin-left: 15%;
    margin-right: 15%;
    text-align: center;
}

h1{
    margin-top: 20vh;
    font-size: 40px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
}

h2{
    margin-top: 15vh;
    font-size: 30px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
}

p{
    font-size: 16px;
    color: white;
}

.signup_icon{
    height: 20px; 
    display: flex;                 
    align-items: center; 
    justify-content: center;
}

.social_icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    text-align: center;
    margin: 0 12px;
    border-radius: 50%;
    border: 1px solid white;
    color: white;
    font-size: 18px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.signup_form{
    display: flex;
    flex-direction: column;
    margin-left: 25%;
    margin-right: 25%;
}

.signup_form .signup_input{
    margin-top: 10px;
    height: 25px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.5);
    border: 0;
}

.signup_input::placeholder{
    color: white;
}

.signin_button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.signin_button_1 {
    margin-top: 40px;
    color: white;
    width: 100px;
    height: 25px;
    border: 3px solid #cc9933;
    border-radius: 6px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
}

.signup_button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.signin_button_2 {
    margin-top: 40px;
    color: white;
    width: 100px;
    height: 25px;
    border: 3px solid #cc9933;
    border-radius: 6px;
    background-color: #cc9933;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
}

.signup_close {
    position:absolute;
    top: 3vh;
    right: 3vh;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.signup_close_icon{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid white;
}

.signup_close_icon::before{
    position:absolute;
    content: '';
    width: 15px;
    height: 2px;
    background-color: white;
    transform: rotate(45deg);
}

.signup_close_icon::after{
    position:absolute;
    content: '';
    width: 15px;
    height: 2px;
    background-color: white;
    transform: rotate(-45deg);
}
</style>